<template>
  <div>
    <h2 class="content-header">{{ $tfo('batch_upload_log') }} - {{ state.filter.current.upload_list }}</h2>

    <el-row class="cols--center mar-v--1" row-key="id">
      <el-button name="back" @click="$router.push({ path: '/batch-upload/filter/limit=10' })">{{ $tfo('back') }}</el-button>
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-left--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>

    <el-table :size="$vars.sizes.table" name="dossiers-log-table" stripe :data="state.items" v-loading="state.loading">
      <el-table-column prop="id" :label="$tf('common.id')" width="60"></el-table-column>
      <el-table-column prop="source_photo" :label="$tf('common.photo')"></el-table-column>
      <el-table-column prop="dossier_id" :label="$tf('common.dossier')" width="100"></el-table-column>
      <el-table-column prop="resp_code" :label="$tf('common.code')" width="100"></el-table-column>
      <el-table-column prop="error_desc" :label="$tf('common.error')"></el-table-column>
      <el-table-column prop="created_date" width="120px" :label="$tf('common.created')">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}
          <br />
          {{ row.created_date | isoStringToDate | formatTime }}
        </template>
      </el-table-column>
    </el-table>

    <el-row class="cols--center">
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import qs from 'qs';
import _ from '@/apps/common/lodash';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: {
    TableNavigation
  },
  name: 'page-batch-upload-entry',
  data: () => ({ hasFilters: false }),
  computed: {
    state() {
      return this.$store.state.batch_upload_entry;
    }
  },
  watch: {
    '$route.params.filter': function (v, p) {
      if (this.applyQueryFilter(v)) {
      }

      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }

      this.loadItems();
    },
    '$store.state.batch_upload_entry.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => !!v),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/batch-upload-entry/filter/' + filterString });
        }
      }
    }
  },
  mounted() {
    this.applyQueryFilter(this.$route.params.filter);
    this.loadItems();
  },
  methods: {
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false;

      objectFilter['limit'] = parseInt(objectFilter['limit']);
      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
      }

      this.hasFilters = !_.isEqual(objectFilter, this.state.filter.empty);
      return !filtersEqual;
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get);
    },
    downloadCsv(e) {
      window.open(this.$store.state.config.server.url + 'batch-upload-log/csv', '_blank');
    },
    deleteAll(v) {
      return this.$store
        .dispatch(this.state.Action.DeleteAll)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.state.Action.Get);
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          this.$store.dispatch(this.state.Action.Get);
        });
    }
  }
};
</script>
